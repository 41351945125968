import axios from 'axios';

/* 选择语言 */
export const selectLanguage = apiParams => {
  return axios({
    method: 'post',
    url: '/user/update/lan',
    data: apiParams
  });
};

export const login = apiParams => {
  return axios({
    method: 'post',
    url: '',
    params: apiParams
  })
}

/* 获取视频讲解类型 */
export const getVideoType = apiParams => {
  return axios({
    method: 'GET',
    url: `/video/types?subject=${apiParams}`,
  })
}
/* 根据对应的类型获取对应 */
export const getVideoList = apiParams => {
  return axios({
    method: 'GET',
    url: `/video/list?subject=${apiParams.car}&videoType=${apiParams.title}`
  })
}

/* 查询错题数量 */
export const errorNum = apiParams => {
  return axios({
    method: 'GET',
    url: '/error/topic/num',
    params: apiParams
  })
}

//  记录错题
export const addmastick = apiParams => {
  return axios({
    method: 'POST',
    url: '/error/topic/save',
    data: apiParams
  })
}

/* 查询所有错题 */
export const errList = apiParams => {
  return axios({
    method: 'GET',
    url: '/error/topic/all',
    params: apiParams
  })
}

/* 查询所有收藏  */
export const clloectList = apiParams => {
  return axios({
    method: 'GET',
    url: '/collect/topic/all',
    params: apiParams
  })
}

/* 查詢所有二手车的伦比图 */
export const carMainSwipe = apiParams => {
  return axios({
    method: 'GET',
    url: '/car/banner/list',
    params: apiParams
  })
}

/* 查询二手车首页列表 */
export const carMainList = apiParams => {
  return axios({
    method: 'GET',
    url: '/car/client/page',
    params: apiParams
  })
}
/* 获取二手车详情 */
export const carDetail = apiParams => {
  return axios({
    method: 'GET',
    url: '/car/client/detail',
    params: apiParams
  })
}
// 查询错题数量以及对应的东西
export const errorNumAndList = apiParams => {
  return axios({
    method: 'GET',
    url: '/error/topic/types',
    params: apiParams
  })
}
// 清空错题
export const errorClear = apiParams => {
  return axios({
    method: 'post',
    url: '/error/topic/clean',
    data: apiParams
  })
}