<template>
  <div class="videoExplanation" ref="VIDEO">
    <van-tabs ref="VIDEOTBS" v-model="active" swipeable sticky>
      <van-tab v-for="(item, index) in typeList" :key="index">
        <div slot="title" @click="tabChange(item)">
          <p style="text-align: center;">{{ item.typeLan }}</p>
          <p style="text-align: center;">{{ item.type }}</p>
        </div>
      </van-tab>
    </van-tabs>
    <div class="mainVideo" :style="{height: tabH + 'px'}">
      <van-loading v-if="loading" type="spinner" color="#1989fa" />
      <div v-else class="boxWarp" :style="{height: tabH + 'px'}">
        <div v-if="list.length === 0" class="nothing">暂无数据</div>
        <div v-else  v-for="item in list" :key="item.id" class="boxMain">
          <div class="video_box">
            <video :src="item.video" poster="@/assets/image/videoimg.png" controls="controls" webkit-playsinline=“true” playsinline=“true” x5-video-orientation=“landscape|portrait” x5-video-player-type=“h5-page” />
          </div>
          <div class="text_warp">
            <p class="text">{{ chooseTitle }} <br /> {{ chooseTitlelan }} </p>
            <p class="text"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "video.js/dist/video-js.css";
import { getVideoType, getVideoList } from "@/api/secondSelect";
export default {
  data() {
    return {
      active: 0,
      list: [],
      loading: false,
      car: '',
      typeList: [],
      chooseTitle: '',
      chooseTitlelan: '',
      tabH: 0
    };
  },
  created() {
    this.car = this.$route.query.id
  },
  mounted() {
    this.tabH = this.$refs.VIDEO.offsetHeight - 65.5
    this.getType();
  },
  methods: {
    /* 获取视频类型 */
    getType() {
      getVideoType(this.car).then((res) => {
        this.typeList = res.data
        this.chooseTitle = res.data[0].type
        this.chooseTitlelan = res.data[0].typeLan
        this.initVideo()
      });
    },
    tabChange(e, title) {
      this.chooseTitle = e.type;
      this.initVideo()
    },
    initVideo() {
      this.loading = true
      getVideoList({ car: this.car, title: this.chooseTitle }).then(res => {
        this.loading = false
        this.list = res.data
      })
    },
  },
};
</script>

<style scoped lang="scss">
.videoExplanation {
  width: 100%;
  height: 100%;
  background: #f0f2f5;
  .video-container {
    position: fixed;
    display: none;
    z-index: 999;
    background: #000;
    .close-icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #fff;
      display: grid;
      place-items: center;
      position: absolute;
      top: 16px;
      right: 20px;
      z-index: 99;
    }
    #my-video {
      width: 100%;
      height: 100%;
    }
  }
  ::v-deep .van-tabs {
    z-index: 99;
  }
  ::v-deep .van-tabs__line {
    background-color: #6493ff;
  }
  .mainVideo{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .boxWarp {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 16px 11px 0;
    overflow-y: scroll;
    .nothing{
      width: 100%;
      padding-top: 40px;
      text-align: center;
    }
    .boxMain {
      width: 346px;
      max-height: 230px;
      margin-bottom: 8px;
      background: #ffffff;
      border-radius: 7px 7px 7px 7px;
      .video_box{
        width: 346px;
        height: 180px;
        border-radius: 7px 7px 0px 0px;
        video{
          width: 100%;
          height: 100%;
          border-radius: 7px 7px 0px 0px;
        }
      }
      .text_warp{
        padding: 5px 8px 0;
      }
      .text{
        width: 154px;
        height: 30px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        font-size: 11px;
        color: #212121;
        line-height: 14px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 定义显示的行数 */
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
