import { render, staticRenderFns } from "./videoExplanation.vue?vue&type=template&id=dbef6fe4&scoped=true"
import script from "./videoExplanation.vue?vue&type=script&lang=js"
export * from "./videoExplanation.vue?vue&type=script&lang=js"
import style0 from "./videoExplanation.vue?vue&type=style&index=0&id=dbef6fe4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbef6fe4",
  null
  
)

export default component.exports